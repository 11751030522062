import React from 'react';

import { Container } from './styles';

export default function Header() {
  return (
    <Container>
      <h1>
        Pipefy-cover
      </h1>
    </Container>
  );
}